import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../components/NavBar.css';
/* import logo from '../assets/img/EnoLogo.svg'; */
import logo from '../assets/img/ENOLogoHeader.webp';

import useSticky from './useSticky'; // Asegúrate de que la ruta sea correcta

function NavBar() {
  const navRef = useRef();
  const isSticky = useSticky();

  const toggleNavbar = () => {
    navRef.current.classList.toggle('responsive_nav');
  };

  const hideNavbar = () => {
    navRef.current.classList.remove('responsive_nav');
  };

  return (
    <header className={isSticky ? 'sticky' : ''}>
      <div className="logo__header">
        <img src={logo} alt="eno" />
      </div>
      <nav ref={navRef}>
        <a href="#trade" onClick={hideNavbar}>
          Trade
        </a>
        <a href="#utility" onClick={hideNavbar}>
          Utility
        </a>
        <a
          href="https://docs.enotoken.io"
          target="_blank"
          rel="noopener noreferrer"
          onClick={hideNavbar}
        >
          Whitepaper
        </a>
        <a href="#blog" onClick={hideNavbar}>
          Blog
        </a>
        <a href="#partners" onClick={hideNavbar}>
          Partners
        </a>
        <a href="#core" onClick={hideNavbar}>
          Core Team
        </a>
        <button className="nav-btn nav-close-btn" onClick={toggleNavbar}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </nav>
      <div>
        <a
          href="https://app.eno.network/"
          rel="noopener noreferrer"
          className="membership-button"
        >
          <button className="hero__btn btn-alternative-navbar">
            Launch App
          </button>
        </a>
      </div>
      <button className="nav-btn" onClick={toggleNavbar}>
        <FontAwesomeIcon icon={faBars} />
      </button>
    </header>
  );
}

export default NavBar;
